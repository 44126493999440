<template>
  <main class="main  product-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <router-link to="/product-list/dota2" class="back"><span>Back</span></router-link>
          <transition name="fade">
            <div class="product-preview" v-if="$parent.productPageItem">
              <div class="preview-container">
                <div class="preview-wrapper">
                  <div class="preview">
                    <transition name="fade">
                      <span class="title" v-if="$parent.addToCartChosenItem == $parent.productPageItem.id">Added</span>
                    </transition>
                    <img :src="$parent.imgDomain + $parent.productPageItem.img_url" class="img"/>
                  </div>
                  <div class="text">
                  <div class="title">{{$parent.productPageItem.title}}</div>
                    <div class="row">
                      <div class="desc">Type:</div>
                      <div class="desc">{{$parent.productPageItem.type}}</div>
                    </div>
                    <div class="row">
                      <div class="desc">Quality:</div>
                      <div class="desc">{{$parent.productPageItem.quality}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="price-container">
                <div class="desc">Price:</div>
                <div class="title">{{$parent.productPageItem.price}} <span class="currency">{{currency}}</span></div>
                <button class="button" @click="$parent.addToCart($parent.productPageItem, $parent.productPageItem.id)">
                  <span>Add to Cart</span>
                </button>
              </div>
            </div>
          </transition>
        </div>
      </section>
      <div class="section arrivals-section recommended-section">
        <div class="wrapper">
          <div class="top">
            <div class="title">
              Recommended
            </div>
            <router-link to="/recommend" class="link">
              <span>View more</span>
            </router-link>
          </div>
          <SlickItem v-if="$parent.similarProducts.length" :list="$parent.similarProducts" :tag="'We Recommend'" :addToCartChosenItem="addToCartChosenItem" @addToCart="addToCart" @goToProduct="goToProduct" :currency="currency" />
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import SlickItem from '../components/SlickItem.vue';
export default {
  name: 'ProductPage',
  props: ['addToCartChosenItem', 'currency'],
  components: {
    SlickItem
  },
  data: function() {
    return {
      
    }
  },
  created() {
  },
  mounted() {
    this.$parent.getProductPageContent(this.$route.params.id);
  },
  methods: {
    addToCart(item, id) {
      this.$parent.addToCart(item, id);
    },
    goToProduct(item) {
      this.$parent.goToProduct(item);
    }
  }
}
</script>