<template>
	<footer class="footer">
      <div class="footer-section">
        <div class="wrapper">
          <div class="left">
            <a href="index.html" class="logo">
              <img src="./../assets/logo.svg" class="img"/>
            </a>
            <div class="text" v-if="$parent.footerRequisites">
              <div class="desc">{{$parent.footerRequisites}}</div>
            </div>
            <div class="social-links-wrapper">
              <div class="social-links">
                <a target="_blank" :href="link.link" v-for="link in $parent.socialLinks" class="link" v-bind:key="link.link">
                  <img :src="link.img" class="link-img" />
                </a>
              </div>
            </div>
          </div>
          <div class="center">
            <ul class="nav">
              <template  v-for="(item, i) in $parent.nav">
                <li class="nav__item" :key="i">
                  <router-link :to="item.link" exact>
                    {{item.title}}
                  </router-link>
                </li>
              </template>
            </ul>
            <ul class="nav">
              <template  v-for="item in $parent.textPageList">
                <li v-if="item && item.title" class="nav__item" :key="item.id">
                  <a @click="$parent.goToPage(item.id)">
                    {{item.title}}
                  </a>
                </li>
              </template>
            </ul>
          </div>
          <div class="right">
            <ul class="list">
              <li class="item" v-if="$parent.footerSupportEmail">
                <div class="title">Support:</div>
                <div class="desc">{{$parent.footerSupportEmail}}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="copyright-section">
        <div class="wrapper">
          <div class="left">
            <div class="desc">
              The Cardholder himself is responsible for compliance with the rules of the copyright holder.
            </div>
            <div class="desc">
              Any trademarks used belong to their respective owners.
            </div>
          </div>
          <div class="right">
            <ul class="list">
              <li class="item">
                <img src="./../assets/mc_symbol.svg" class="img"/>
              </li>
              <li class="item">
                <img src="./../assets/visa.svg" class="img"/>
              </li>
              <li class="item">
                <img src="./../assets/3ds.png" class="img"/>
              </li>
              <li class="item">
                <img src="./../assets/google-pay-primary-logo-logo-svgrepo-com.svg" class="img"/>
              </li>
              <li class="item">
                <img src="./../assets/apple-pay.svg" class="img"/>
              </li>
            </ul>
            <div class="desc" v-if="$parent.footerCopyright">{{$parent.footerCopyright}}</div>
          </div>
        </div>
      </div>
    </footer>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
		}
	},
	methods: {
		
	},
	mounted() {
    
  }
}
</script>
