<template>
	<header class="header">
        <div class="wrapper">
          <div class="left">
            <router-link to="/" class="logo">
              <img src="./../assets/logo.svg" class="img"/>
              <img src="./../assets/logo-mini.svg" class="mob img"/>
            </router-link>
            <ul class="nav">
              <template v-for="(item, i) in $parent.nav">
                <li class="nav__item" :key="i">
                  <router-link :to="item.link" exact>{{item.title}}</router-link>
                </li>
              </template>
            </ul>
          </div>
          <div class="right">
            <router-link to="/cart" v-if="$parent.isAuth" :class="['icon', {'gray': !cartContents.length}]">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                <defs>
                  <clipPath id="clip-path">
                    <path id="path2235" d="M0-682.665H24v24H0Z" transform="translate(0 682.665)" fill="#57586f"/>
                  </clipPath>
                </defs>
                <g id="shopping-cart_2_" data-name="shopping-cart (2)" transform="translate(0 -0.002)">
                  <g id="g2229" transform="translate(0 0.002)">
                    <g id="g2231" transform="translate(0 0)">
                      <g id="g2233" clip-path="url(#clip-path)">
                        <g id="g2239" transform="translate(1.464 10.875)">
                          <path id="path2241" d="M-528.64-262.476h-11.584a3.752,3.752,0,0,1-3.723-3.3L-545-274.551a.938.938,0,0,1,.819-1.042.937.937,0,0,1,1.042.819l1.051,8.772a1.876,1.876,0,0,0,1.862,1.652h11.584a1.874,1.874,0,0,0,1.863-1.666.938.938,0,0,1,1.036-.827.938.938,0,0,1,.827,1.036A3.747,3.747,0,0,1-528.64-262.476Z" transform="translate(545.005 275.601)" fill="#57586f"/>
                        </g>
                        <g id="g2243" transform="translate(0 7.125)">
                          <path id="path2245" d="M-486.878-123.36a.947.947,0,0,1-.106-.006.938.938,0,0,1-.827-1.036l.489-4.348a.937.937,0,0,1,.932-.833h.517v-1.875h-20.25v1.875h15.984a.938.938,0,0,1,.938.938.938.938,0,0,1-.937.938h-16.922a.938.938,0,0,1-.937-.938v-3.75a.938.938,0,0,1,.938-.938h22.125a.937.937,0,0,1,.938.938v3.75a.937.937,0,0,1-.937.938h-.617l-.395,3.515A.938.938,0,0,1-486.878-123.36Z" transform="translate(507.999 133.333)" fill="#57586f"/>
                        </g>
                        <g id="g2247" transform="translate(13.125 0)">
                          <path id="path2249" d="M-165.617-215.189a.936.936,0,0,1-.756-.382l-5.1-6.938a.937.937,0,0,1,.2-1.311.938.938,0,0,1,1.311.2l5.1,6.938a.938.938,0,0,1-.2,1.311A.934.934,0,0,1-165.617-215.189Z" transform="translate(171.652 224.001)" fill="#57586f"/>
                        </g>
                        <g id="g2251" transform="translate(3.937 0)">
                          <path id="path2253" d="M-170.715-17.856a.933.933,0,0,1-.554-.182.937.937,0,0,1-.2-1.311l5.1-6.937a.938.938,0,0,1,1.311-.2.937.937,0,0,1,.2,1.311l-5.1,6.938A.936.936,0,0,1-170.715-17.856Z" transform="translate(171.652 26.668)" fill="#57586f"/>
                        </g>
                        <g id="g2255" transform="translate(11.063 14.625)">
                          <path id="path2257" d="M-25.729-21.042a.938.938,0,0,1-.937-.938v-3.75a.938.938,0,0,1,.938-.938.938.938,0,0,1,.937.938v3.75A.938.938,0,0,1-25.729-21.042Z" transform="translate(26.667 26.667)" fill="#57586f"/>
                        </g>
                        <g id="g2259" transform="translate(14.813 14.625)">
                          <path id="path2261" d="M-25.729-21.042a.938.938,0,0,1-.937-.938v-3.75a.938.938,0,0,1,.938-.938.938.938,0,0,1,.937.938v3.75A.938.938,0,0,1-25.729-21.042Z" transform="translate(26.667 26.667)" fill="#57586f"/>
                        </g>
                        <g id="g2263" transform="translate(7.313 14.625)">
                          <path id="path2265" d="M-25.729-21.042a.938.938,0,0,1-.937-.938v-3.75a.938.938,0,0,1,.938-.938.938.938,0,0,1,.937.938v3.75A.938.938,0,0,1-25.729-21.042Z" transform="translate(26.667 26.667)" fill="#57586f"/>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <div v-if="cartContents.length" class="indicator">
                {{cartContents.length}}
              </div>
            </router-link>
            <div class="button link-container dropdown-open" v-if="$parent.isAuth">
              <img src="./../assets/user.svg" class="img"/>
              <span>My account</span>
              <div class="arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.808" height="10" viewBox="0 0 16.808 10">
                  <path id="Контур_195" data-name="Контур 195" d="M9.233,10.829a1.6,1.6,0,0,1-1.139-.469l-6.8-6.8A1.6,1.6,0,0,1,3.563,1.3l5.67,5.67L14.9,1.3a1.6,1.6,0,0,1,2.266,2.266l-6.8,6.8a1.6,1.6,0,0,1-1.133.469Z" transform="translate(-0.829 -0.829)" fill="#1b1a1d"/>
                </svg>
              </div>
              <div class="dropdown">
                <div class="dropdown-wrapper">
                  <div class="nav__item">
                    <router-link to="/profile">
                      Overview
                    </router-link>
                  </div>
                  <!-- <div class="nav__item">
                    <router-link to="sell-offers">
                      Sell Offers
                    </router-link>
                  </div> -->
                  <div class="nav__item nav__item-logout">
                    <a @click="logout">
                      <span>LogOut</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="link-container" v-else>
              <div class="nav__item nav__item-signIn" @click="openSignIn">
                <a>Sign In</a>
              </div>
              <div class="button modal-open" @click="openSignUp">
                <span>Sign Up</span>
              </div>
            </div>
          </div>
        </div>
    </header>
</template>

<script>

export default {
	name: 'Header',
  props: ['cartContents'],
	data: function() {
		return {
		}
	},
	methods: {
    openSignIn() {
      this.$router.push({path: '/login'});
    },
    openSignUp() {
      this.$router.push({path: '/registration'});
    },
    openSaleSkinModal() {
      this.$emit('openSaleSkinModal')
    },
    logout() {
      this.$emit('logout')
    }
	}
}
</script>
