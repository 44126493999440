<template>
	<div class="menu">
		<ul class="nav">
			<template v-for="(item, i) in $parent.nav">
			<li class="nav__item" :key="i" v-if="item.link != '/privacy' && item.link != '/terms'">
				<router-link :to="item.link" exact>
				<span v-if="item.title == 'New Arrivals'">New</span> 
				<template v-if="item.title == 'New Arrivals'">
					Arrivals
				</template>
				<template v-else>
					{{item.title != 'Sale'? item.title : ''}}
				</template>
				<span class="red" v-if="item.title == 'Sale'">Sale</span>
				</router-link>
			</li>
			</template>
			<!-- <div class="nav__item" v-if="$route.name == 'Profile'  || $route.name == 'SellOffers'">
			<a @click="$emit('openSaleSkinModal')">Sale your skin</a>
			</div> -->
		</ul>
	</div>
</template>

<script>
/*import Vue from 'vue';
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)*/
export default {
	name: 'Menu',
	props: [],
	data: function() {
		return {
			
		}
	},
	methods: {
		
	},
}
</script>
