<template>
	<div class="item-wrapper">
		<div class="price-container">
			<div class="desc">{{item.type}}</div>
			<div class="price title">
				<span class="discount" v-if="item.old_price != '0.00'">{{item.old_price}} <span class="currency">{{currency}}</span></span>
				<span>{{item.price}} <span class="currency">{{currency}}</span></span>
			</div>
		</div>
		<div class="preview">
			<img @click="goToSliderItem(item)" :src="imgDomain + item.img_url" class="img"/>
			<div v-if="tag" class="tag">
				<span>{{tag}}</span>
			</div>
			<transition name="fade">
				<span class="title" v-if="addToCartChosenItem == item.id">Added</span>
			</transition>
		</div>
		<a @click="goToSliderItem(item)" class="title">{{item.title}}</a>
		<a @click="$emit('addToCart',item, item.id)" class="link button">
			<span>Add to Cart</span>
		</a>
	</div>
</template>

<script>

export default {
	name: 'ProductCard',
	props: ['item', 'addToCartChosenItem', 'sliderIsClickble', 'tag', 'currency'],
	data: function() {
		return {
			imgDomain: ''
		}
	},
	mounted() {
		this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
	},
	methods: {
		goToSliderItem(item) {
			let self = this;
			setTimeout(()=> {
				if (self.sliderIsClickble == true) {
					self.$emit('goToProduct', item);
				}
			}, 200)
		},
	},
}
</script>
