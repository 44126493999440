<template>
	<div class="features-section">
		<ul class="list">
		<li class="item">
			<div class="icon">
				<img src="./../assets/wex.png" class="img"/>
			</div>
			<div class="title">100% Secure</div>
			<div class="desc small">All transactions are thoroughly monitored, so your money and skins are safe.</div>
		</li>
		<li class="item">
			<div class="icon">
				<img src="./../assets/qas.png" class="img"/>
			</div>
			<div class="title">Wide Choice</div>
			<div class="desc small">There are many variations of unique and high quality skins.</div>
		</li>
		<li class="item">
			<div class="icon">
				<img src="./../assets/exort.png" class="img"/>
			</div>
			<div class="title">Best Prices</div>
			<div class="desc small">Looking good is just as important as playing well in Dota 2 .Upgrade your style and dominate the game.</div>
		</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'Features',
	props: [],
	data: function() {
		return {
			
		}
	},
	methods: {
		
	},
}
</script>
